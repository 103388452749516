<template >
    <div id="EditAsr">
               <div class="form-content">
            <form @submit.prevent="
                submit( added.name, added.email, added.role, added.status, added.orden )">

<p class="subtitle">Visibilidad</p>
        <div class="dflx">
          <p
            :class="{ statusVisible: added.status == 1 }"
            class="btnStatus"
            @click="added.status = 1"
          >
            VISIBLE
          </p>
          <p
            :class="{ statusVisible: added.status == 0 }"
            class="btnStatus"
            @click="added.status = 0"
          >
            OCULTO
          </p>
        </div>

        <div class="grup-form dflx">
          <div class="boxform">
            <label for="name" class="name">Nombre </label>
            <input
              v-model="added.name"
              type="text"
              id="name"
              name="name"
              required
            />
          </div>
          <div class="boxform">
            <label for="role" class="role">Rol</label>
            <input
              v-model="added.role"
              type="text"
              id="role"
              name="role"
            />
          </div>
          <div class="boxform">
            <label for="email" class="email">Email</label>
            <input
              v-model="added.email"
              type="email"
              id="email"
              name="email"
            />
          </div>
        </div>

        <div class="grup-form dflx">
          <div class="boxform">
            <label for="orden" class="orden">ORDEN  <span style="font-weight: 300;">(0 no aplica orden, seran los ultimos)</span> </label>
            <input
              v-model="added.orden"
              type="number"
              id="orden"
              name="orden"
              required
              min="0"
              step="1"
            />
          </div>
        
        </div>


                      <div class="dflx">
                               <p @click="wait()" class="btnRegresar">Regresar</p>
                               <button class="alta" type="submit">Editar </button>
                        </div>

                    
                        <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
                        </div>
            </form>


                     
          


        </div>

                <hr class="hrhd">

                <div class="form-img">
                           
                   
                     <div class="grup-form dflx">
                        <div class="boxform">
                            <label >Imagen</label>
                          <div class="dflx">
                               <label class="filelabel" for="uploadimg"> <span>Seleccionar Archivo</span></label>  <label class="filelabelname tbl_item" for="uploadimg">   {{archivo}}</label>
                        </div> 
                            
                            <input @change="previewFiles" type="file" id="uploadimg" name="uploadimg"    required class="fileinput">
                        </div>
                       
                    </div>

                      <div class="dflx">
                          
                      <div  @click="submitImg(file1)"  class="btn_añadir2 dflx">
                                <p  > AGREGA <span> IMAGEN</span> </p>  <img src="../../assets/upload.png" alt="">
                     </div>
                              
                        </div>
                   

                                            
                   <div class="div-user">
                               <img class="logo"  :src="srcImg(added.image)" alt="">
                    </div>
                                    
                   

                    
                        <div v-if="status2 !=''" class="status_messages">
                            <div v-if="status2 =='success'" class="msg msg_success">
                                 <p>{{message2}}</p>
                            </div>
                            <div  v-if="status2 =='error'" class="msg msg_error">
                                <p>{{message2}}</p>
                            </div>
                        </div>
                        
                </div>

            <div v-if="viewModal" class="modal_view">
           
            <div id="modal_edit">
                <div class="body dflx">
                     
                           <h3>Usuario actualizado </h3>
                           <img src="../../assets/add.png" alt="icono alerta">
                </div>
           
            <div class="modal_edit_btns dflx">
                <p @click="wait()" class="otro">Aceptar</p> <!-- <p  @click="back()" class="inicio">Inicio</p>-->
            </div>
          </div>
        </div> 

    </div>
</template>
<script>
import urlpath from '../../global/url'; 
import {mapActions} from 'vuex';
export default {
    name:'EditAsr',
    
    data(){
        return{
            urlpath,
           status:'',
           message:'',
           status2:'',
           message2:'',
           active: false,
           pass:"",
           passc:"",
           archivo:'No se eligió archivo',

            //MODAL
 file1:null,
            viewModal:false,
          
        }
    },
    created (){
        let payload={
            id:this.added.id,
            option: "about_user",
        }
          this.getInfoByIdAsr(payload);
    },  
    computed:{
        added(){
            return this.$store.getters["aboutuser/getAdded"]
        },
         data() {
            return this.$store.getters["aboutuser/data"];
        },
    },
    methods:{
          ...mapActions('aboutuser', ['setAddedAsr']),
         ...mapActions('aboutuser', ['setHistoryOptionAsr']),
          wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
        ...mapActions("aboutuser", ["getInfoByIdAsr"]),
        
        submit: async function(name, email, role, status, orden ){
            this.status =''
            this.message =''
            let result = await this.$store.dispatch("aboutuser/editItemAsr",  {option:'about_user', item: { id: this.added.id , name, email, role, status, orden }});
        
            if(result.status=='error'){
                this.status='error'
                this.message= result.message
            }else{ // success
                this.showModal()
                
            } 
        },
        closeSelect() {
        this.active = false;
        document.getElementById("select-ch").checked = false;
        },
     submitImg: async function (image) {
        this.status=''
        this.msg=''
        
       if(image !=null){
           if(image.size > 5000000){
                this.status2='error'
                 this.message2= 'La imagen excede el tamaño maximo permitido de 1MB.'
                   this.delStatus()
           }else{
                var data = new  FormData();
                data.append('image', image);
                data.append('id', this.added.id);
                data.append('_method', 'PUT');
                let result = await   this.$store.dispatch("main/editItem", {id:this.added.id, item:data, option:'about_user/image'});

       
                if(result.status=='error'){
                    this.status2='error'
                    this.message2= result.message
                      this.delStatus()
                }else{ // success
                    this.showModal()
                    
                }
           }
      }else{

            this.status2='error'
           this.message2= 'Imagen no subida.'
           this.delStatus()
        
      
      }
      
     
    },

     delStatus: function () {
            setTimeout(() => this.delMsgs()
            ,2000);
           
            
        },
        delMsgs: function(){
            this.status=""
            this.message=""
             this.status2=""
            this.message2=""
        },


      showModal: function(){
         this.viewModal=true
     },
    closeModal:function(){
        this.viewModal=false;
        this.setAddedAsr('')
        this.setHistoryOptionAsr('Default')
    },
    Edit:function(){
        this.viewModal=false;
    },
    back:function(){
       setTimeout(() => this.$router.go(), 200);
    },
    srcImg:   function (img){
        if(!img || img =='') return ''
        let src = `${urlpath.url()}/about_user-img/${img}`;
          return src
        },


    previewFiles(e) {
     
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        this.file1= files[0];
        this.archivo = this.file1.name
        },
   }


}
</script>
<style scoped>

p.btnStatus {
  cursor: pointer;
  border: none;
  background: var(--color-4);
  color: var(--color-5);
  width: 6vw;
  text-align: center;
  padding: 0.5vw 0vw;
  margin-right: 1vw;
  border-radius: 1.5vw;
  margin-top: 0.7291666666666666vw;
  font-weight: 600;
  font-size: 0.7291666666666666vw;
}

.statusVisible {
  background: #f8b841 !important;
}
   
    #EditAsr label{
        color: var(--color-2);
        font-weight: 700;
        font-size: 0.7291666666666666VW;
        margin-bottom: .3vw;
    }

    #EditAsr input, #EditAsr select{
        height: 2.5VW;
        width: 14.716666666666668VW;
        border: 0.052083vw solid var(--color-2);
        border-radius: 0vw  1.5vw 1.5vw  0vw ;
        color: var(--color-4);
        font-size: 0.7291666666666666VW;
        font-weight: 500;
        padding-left: 1.09375VW;
    }

    #select-role {
  height: 2.5vw;
  width: 13.716667vw;
  border: 0.052083vw solid var(--color-2);
  border-radius: 0vw 1.5vw 1.5vw 0vw;
  color: var(--color-4);
  font-size: 0.7291666666666666vw;
  font-weight: 500;
  padding-left: 1.09375vw;
  display: flex;
  align-items: center;
  background-image: url("../../assets/blueflecha.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 12.6vw;
}
#select-role.rounded {
  border-radius: 0vw 1.5vw 0vw 0vw;
    border-left: 0.052083vw solid var(--color-2);
    border-right: 0.052083vw solid var(--color-2);
    border-top: 0.052083vw solid var(--color-2);
    border-bottom: 0;
}


    
</style>