var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dflx",attrs:{"id":"SideMenu"}},[_c('div',{staticClass:"box-1"},[_c('div',{staticClass:"logo"},[(_vm.validar())?_c('img',{attrs:{"src":_vm.srcImg(_vm.logo.logo),"alt":""}}):_vm._e()]),_c('div',{staticClass:"msg-user"},[_c('p',[_vm._v(" ¡HOLA, "),_c('span',[_vm._v(_vm._s(_vm._f("splitUp")(_vm.identity.name))+"!")])])]),_c('div',{staticClass:"menu"},[_c('ul',[_c('li',{class:{ active: _vm.navOption == 'Dashboard' },on:{"click":function($event){_vm.activeMenu(0), _vm.miPagina()}}},[_vm._m(0),(_vm.navOption == 'Mi Página')?_c('div',{staticClass:"icon-active"},[_c('div',{staticClass:"ico"})]):_vm._e()]),_c('li',{class:{ active: _vm.navOption == 'Personalizacion' },on:{"click":function($event){_vm.activeMenu(0),
              _vm.setOption('Personalizacion'),
              _vm.setHistoryOptionPrf('Default')}}},[_vm._m(1),(_vm.navOption == 'Personalizacion')?_c('div',{staticClass:"icon-active"},[_c('div',{staticClass:"ico"})]):_vm._e()]),_c('li',{class:{ active: _vm.navOption == 'Conocenos' },on:{"click":function($event){_vm.activeMenu(0),
              _vm.setOption('Conocenos'),
              _vm.setHistoryOptionAsr('Default')}}},[_vm._m(2),(_vm.navOption == 'Conocenos')?_c('div',{staticClass:"icon-active"},[_c('div',{staticClass:"ico"})]):_vm._e()]),_c('li',{class:{ active: _vm.navOption == 'Banner' },on:{"click":function($event){_vm.activeMenu(0), _vm.setOption('Banner'), _vm.setHistoryOptionBnr('Default')}}},[_vm._m(3),(_vm.navOption == 'Banner')?_c('div',{staticClass:"icon-active"},[_c('div',{staticClass:"ico"})]):_vm._e()]),_c('li',{class:{ active: _vm.navOption == 'Slide' },on:{"click":function($event){_vm.activeMenu(0), _vm.setOption('Slide'), _vm.setHistoryOptionSld('Default')}}},[_vm._m(4),(_vm.navOption == 'Slide')?_c('div',{staticClass:"icon-active"},[_c('div',{staticClass:"ico"})]):_vm._e()]),_c('li',{class:{ active: _vm.navOption == 'Usuarios' },on:{"click":function($event){_vm.activeMenu(0),
              _vm.setOption('Usuarios'),
              _vm.setHistoryOptionUsr('Default')}}},[_vm._m(5),(_vm.navOption == 'Usuarios')?_c('div',{staticClass:"icon-active"},[_c('div',{staticClass:"ico"})]):_vm._e()]),_c('li',{class:{ active: _vm.navOption == 'MenuB' },on:{"click":function($event){_vm.activeMenu(1), _vm.setOption('MenuB')}}},[_vm._m(6),(_vm.navOption == 'MenuB' || _vm.hidemenu)?_c('div',{staticClass:"icon-active-menu"},[_c('div',{staticClass:"ico-2"})]):_vm._e()]),(_vm.hidemenu)?_c('li',{staticClass:"submenu",class:{ active: _vm.navOption == 'BLOG' },on:{"click":function($event){_vm.setOption('BLOG'), _vm.setHistoryOptionBlo('Default')}}},[_vm._v(" ⦁ BLOG ")]):_vm._e(),_c('li',{class:{ active: _vm.navOption == 'BLOGCAT' },on:{"click":function($event){_vm.activeMenu(0),_vm.setOption('BLOGCAT'), _vm.setHistoryOptionCat('Default')}}},[_vm._m(7),(_vm.navOption == 'BLOGCAT')?_c('div',{staticClass:"icon-active"},[_c('div',{staticClass:"ico"})]):_vm._e()]),_c('li',{class:{ active: _vm.navOption == 'colaboradores' },on:{"click":function($event){_vm.activeMenu(0),
              _vm.setOption('colaboradores'),
              _vm.setHistoryOptionClb('Default')}}},[_vm._m(8),(_vm.navOption == 'colaboradores')?_c('div',{staticClass:"icon-active"},[_c('div',{staticClass:"ico"})]):_vm._e()]),_c('li',{class:{ active: _vm.navOption == 'TAG' },on:{"click":function($event){_vm.activeMenu(0), _vm.setOption('TAG'), _vm.setHistoryOptionTg('Default')}}},[_vm._m(9),(_vm.navOption == 'TAG')?_c('div',{staticClass:"icon-active"},[_c('div',{staticClass:"ico"})]):_vm._e()]),_c('li',{class:{ active: _vm.navOption == 'suscripciones' },on:{"click":function($event){_vm.activeMenu(0), _vm.setOption('suscripciones'), _vm.setHistoryOptionSbs('Default')}}},[_vm._m(10),(_vm.navOption == 'suscripciones')?_c('div',{staticClass:"icon-active"},[_c('div',{staticClass:"ico"})]):_vm._e()])])])]),_vm._m(11)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-menu"},[_vm._v("MI "),_c('span',[_vm._v(" PÁGINA")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-menu"},[_c('span',[_vm._v("PERSONALIZACIÓN")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-menu"},[_c('span',[_vm._v("CONOCENOS")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-menu"},[_c('span',[_vm._v("BANNER")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-menu"},[_c('span',[_vm._v("SLIDES")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-menu"},[_c('span',[_vm._v("USUARIOS")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-menu"},[_vm._v("MENÚ "),_c('span',[_vm._v(" BLOG")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-menu"},[_c('span',[_vm._v("CATEGORÍA")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-menu"},[_c('span',[_vm._v("COLABORADORES")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-menu"},[_c('span',[_vm._v("ETIQUETAS")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-menu"},[_c('span',[_vm._v("NEWSLETTER")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-2"},[_c('div',{staticClass:"triangulo"})])}]

export { render, staticRenderFns }