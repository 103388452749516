<template>
  <div id="Sections">
    <HeaderSec />

    <div v-if="historyOption == 'Usuarios'">
      <Usuarios />
    </div>
    <div v-else-if="historyOption == 'Default'">
      <Usuarios />
    </div>
    <div v-else-if="historyOption == 'Conocenos'">
      <Conocenos />
    </div>
    <div v-else-if="historyOption == 'BLOG'">
      <Blog />
    </div>
    <div v-else-if="historyOption == 'BLOGCAT'">
      <Category />
    </div>
    <div v-else-if="historyOption == 'TAG'">
      <Tag />
    </div>
    <div v-else-if="historyOption == 'colaboradores'">
      <Colab />
    </div>
    <div v-else-if="historyOption == 'Slide'">
      <Slide />
    </div>
    <div v-else-if="historyOption == 'Banner'">
      <Banner />
    </div>
    <div v-else-if="historyOption == 'suscripciones'">
      <Suscripciones />
    </div>
        <div v-else-if="historyOption == 'Personalizacion'">
             <Perfil/>
         </div>
         <div v-else>
          <Usuarios />
         </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

import HeaderSec from "../components/HeaderSec.vue";
import Usuarios from "../views/usuarios/usuarios.vue";
import Blog from "../views/blog/blog.vue";
import Tag from "../views/tag/tag.vue";
import Category from "../views/category/category.vue";
import Colab from "../views/colaboradores/colaboradores.vue";
import Slide from "../views/slide/slide.vue";
import Banner from "../views/banner/banner.vue";
import Perfil from '../views/profile/profile.vue';
import Suscripciones from "../views/suscripciones/suscripciones.vue";
import Conocenos from "../views/aboutuser/aboutuser.vue";
export default {
  name: "Sections",
  components: {
    HeaderSec,
    Usuarios,
    Blog,
    Tag,
    Category,
    Colab,
    Slide,
    Banner,
    Perfil,
    Suscripciones,
    Conocenos
  },

  data() {
    return {
      search_value: "",
      status: "",
      message: "",
      option: "Default",
    };
  },
  computed: {
    historyOption() {
      return this.$store.getters["main/getHistoryOption"];
    },
  },
  created() {
    let option = localStorage.getItem("nav");
    if (option != undefined && option != null && option != "") {
      this.option = option;
      this.setHistoryOptionMain(option);
    }
  },
  methods: {
    ...mapActions("main", ["setHistoryOptionMain"]),
    ...mapActions("main", ["getHistoryOption"]),
  },
};
</script>
<style scoped>
#Sections {
  background: var(--color-5);
  min-height: 59.25vw;
  width: 78.17708333333333vw;
  margin-left: 4.166666666666666vw;
}

.proximamente p {
  color: var(--color-1);
  font-weight: 700;
  font-size: 5vw;
  margin-top: 11.5vw;
  margin-left: 15vw;
}
</style>
