<template >
  <div id="AddAsr">
    <div class="form-content">
      <form
        @submit.prevent="
          submit(name, email, role, status, orden)
        "
      >

       <p class="subtitle">Visibilidad</p>
        <div class="dflx">
          <p
            :class="{ statusVisible: status == 1 }"
            class="btnStatus"
            @click="status = 1"
          >
            VISIBLE
          </p>
          <p
            :class="{ statusVisible: status == 0 }"
            class="btnStatus"
            @click="status = 0"
          >
            OCULTO
          </p>
        </div>

        <div class="grup-form dflx">
          <div class="boxform">
            <label for="name" class="name">Nombre </label>
            <input
              v-model="name"
              type="text"
              id="name"
              name="name"
              required
            />
          </div>
          <div class="boxform">
            <label for="role" class="role">Rol</label>
            <input
              v-model="role"
              type="text"
              id="role"
              name="role"
            />
          </div>
          <div class="boxform">
            <label for="email" class="email">Email</label>
            <input
              v-model="email"
              type="email"
              id="email"
              name="email"
            />
          </div>
        </div>

        <div class="grup-form dflx">
          <div class="boxform">
            <label for="orden" class="orden">ORDEN  <span style="font-weight: 300;">(0 no aplica orden, seran los ultimos)</span> </label>
            <input
              v-model="orden"
              type="number"
              id="orden"
              name="orden"
              required
              min="0"
              step="1"
            />
          </div>
        
        </div>

        <div class="dflx">
          <p @click="wait()" class="btnRegresar">Regresar</p>
          <button class="alta" type="submit">Guardar</button>
        </div>

        <div v-if="status != ''" class="status_messages">
          <div v-if="status == 'success'" class="msg msg_success">
            <p>{{ message }}</p>
          </div>
          <div v-if="status == 'error'" class="msg msg_error">
            <p>{{ message }}</p>
          </div>
        </div>
      </form>

      <div v-if="viewModal" class="modal_view">
        <div id="modal_add">
          <div class="body dflx">
            <h3>Usuario agregado</h3>
            <img src="../../assets/add.png" alt="icono alerta" />
          </div>

          <div class="modal_add_btns dflx">
            <p @click="Add()" class="otro">Aceptar</p>
            <p @click="toEdit()" class="editar">Editar</p>
            <p @click="back()" class="inicio">Inicio</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "AddAsr",
  data() {
    return {
      status: "",
      message: "",
      active: false,
      name: "", 
      email: "", 
      role: "", 
      status: 1, 
      orden: 0,
      viewModal: false,
    };
  },
  methods: {
    ...mapActions("aboutuser", ["setAddedAsr"]),
    ...mapActions("aboutuser", ["setHistoryOptionAsr"]),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    submit: async function (name, email, role, status, orden) {
      this.status = "";
      this.message = "";

      const result = await this.$store.dispatch("aboutuser/addItemAsr", {
        option: "about_user",
        item: { name, email, role, status, orden },
      });

      if (result.status == "error") {
        this.status = "error";
        this.message = result.message;
      } else {
        this.setAddedAsr(result.added);
        this.showModal();
      }
    
    },
    closeSelect() {
      this.active = false;
      document.getElementById("select-ch").checked = false;
    },

    showModal: function () {
      this.viewModal = true;
    },
    closeModal: function () {
      this.viewModal = false;
      this.setAddedAsr("");
      this.setHistoryOptionAsr("Default");
    },
    Add: function () {
      this.status = "";
      this.message = "";
      this.name = "";
      this.email = "";
      this.role = "";
      this.status = 1;
      this.orden = 0;
      this.viewModal = false;
      this.setAddedAsr("");
    },
    toEdit: function () {
      this.setHistoryOptionAsr("Edit");
      this.viewModal = false;
    },
    back: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    delStatus: function () {
      setTimeout(() => this.delMsgs(), 2000);
    },
    delMsgs: function () {
      this.status = "";
      this.message = "";
      this.status2 = "";
      this.message2 = "";
    },
  },
};
</script>
<style scoped>
p.btnStatus {
  cursor: pointer;
  border: none;
  background: var(--color-4);
  color: var(--color-5);
  width: 6vw;
  text-align: center;
  padding: 0.5vw 0vw;
  margin-right: 1vw;
  border-radius: 1.5vw;
  margin-top: 0.7291666666666666vw;
  font-weight: 600;
  font-size: 0.7291666666666666vw;
}

.statusVisible {
  background: #f8b841 !important;
}

#AddAsr label {
  color: var(--color-2);
  font-weight: 700;
  font-size: 0.7291666666666666vw;
  margin-bottom: 0.3vw;
}

#AddAsr input,
#AddAsr select {
  height: 2.5vw;
  width: 14.716666666666668vw;
  border: 0.052083vw solid var(--color-2);
  border-radius: 0vw 1.5vw 1.5vw 0vw;
  color: var(--color-4);
  font-size: 0.7291666666666666vw;
  font-weight: 500;
  padding-left: 1.09375vw;
}

#select-role {
  height: 2.5vw;
  width: 13.716667vw;
  border: 0.052083vw solid var(--color-2);
  border-radius: 0vw 1.5vw 1.5vw 0vw;
  color: var(--color-4);
  font-size: 0.7291666666666666vw;
  font-weight: 500;
  padding-left: 1.09375vw;
  display: flex;
  align-items: center;
  background-image: url("../../assets/blueflecha.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 12.6vw;
}
#select-role.rounded {
  border-radius: 0vw 1.5vw 0vw 0vw;
  border-left: 0.052083vw solid var(--color-2);
  border-right: 0.052083vw solid var(--color-2);
  border-top: 0.052083vw solid var(--color-2);
  border-bottom: 0;
}

</style>